import axios from '@/api/axios'

// 服务器代理地址
const BASE_URL = process.env.VUE_APP_BASE_URL

// ================================= 《 运 营 中 心 》

// 通过充值模版ID获取与前端一样的模版展示数据
export function chargeAppDetail(parameter) {
  return axios({
    url: BASE_URL + '/front/charge/coin_list',
    method: 'get',
    params: parameter
  })
}

// =================================《 首 页 》

// 充值信息
export function dataPay(parameter) {
  return axios({
    url: BASE_URL + '/data/pay',
    method: 'get',
    params: parameter
  })
}

// 充值用户
export function dataPayUser(parameter) {
  return axios({
    url: BASE_URL + '/data/pay_user',
    method: 'get',
    params: parameter
  })
}

// 新增用户数
export function dataNewUser(parameter) {
  return axios({
    url: BASE_URL + '/data/new_user',
    method: 'get',
    params: parameter
  })
}

// 充值率+待入库数
export function dataPayRate(parameter) {
  return axios({
    url: BASE_URL + '/data/pay_rate',
    method: 'get',
    params: parameter
  })
}

// 24小时走势图
export function dataTrend(parameter) {
  return axios({
    url: BASE_URL + '/data/hours_list',
    method: 'get',
    params: parameter
  })
}

// =================================《 项 目 》

// 成员项目列表
export function projectList(parameter) {
  return axios({
    url: BASE_URL + '/user/project_list',
    method: 'get',
    params: parameter
  })
}

// ===================================《 片 库 》

// 片库列表
export function getDramaList(parameter) {
  return axios({
    url: BASE_URL + '/project_drama/list',
    method: 'get',
    params: parameter
  })
}

// 待入库列表
export function getDramaUnList(parameter) {
  return axios({
    url: BASE_URL + '/project_drama/un_list',
    method: 'get',
    params: parameter
  })
}

// 片库详情
export function getDramaDetail(parameter) {
  return axios({
    url: BASE_URL + `/project_drama/detail/${parameter.id}`,
    method: 'get',
    params: parameter
  })
}

// 片库编辑
export function editDrama(parameter) {
  return axios({
    url: BASE_URL + `/project_drama/edit`,
    method: 'post',
    data: parameter
  })
}

// 入库
export function storageDrama(parameter) {
  return axios({
    url: BASE_URL + `/project_drama/storage`,
    method: 'post',
    data: parameter
  })
}

// 批量上下架
export function setDramaShelf(parameter) {
  return axios({
    url: BASE_URL + `/project_drama/upper_lower`,
    method: 'post',
    data: parameter
  })
}

// 片库剧集列表
export function getDramaSeriesList(parameter) {
  return axios({
    url: BASE_URL + `/project_drama/video_list/${parameter.project_drama_id}`,
    method: 'get',
    params: parameter
  })
}

// 修改剧集简介
export function editDescrption(parameter) {
  return axios({
    url: BASE_URL + `/project_drama/video_description`,
    method: 'post',
    data: parameter
  })
}

// 转发配置详情
export function qyMessageDetail(parameter) {
  return axios({
    url: BASE_URL + `/project_drama/qy_message_detail`,
    method: 'get',
    params: parameter
  })
}

// 企业微信转发配置
export function qyMessageSetting(parameter) {
  return axios({
    url: BASE_URL + `/project_drama/qy_message_setting`,
    method: 'post',
    data: parameter
  })
}




// ===================================《 用户管理 》

// 充值列表
export function rechargeList(parameter) {
  return axios({
    url: BASE_URL + '/recharge/list',
    method: 'get',
    params: parameter
  })
}

// 新增充值
export function rechargeSave(parameter) {
  return axios({
    url: BASE_URL + '/recharge/save',
    method: 'post',
    data: parameter
  })
}

// ===================================《 前台配置 》

// 前台公共配置-设置
export function frontSourceSetting(parameter) {
  return axios({
    url: BASE_URL + `/front/${parameter.source}/setting`,
    method: 'post',
    data: parameter
  })
}

// 前台公共配置-详情
export function frontSourceSettingDetail(parameter) {
  return axios({
    url: BASE_URL + `/front/${parameter.source}/setting_detail`,
    method: 'get',
    params: parameter
  })
}

// 配置列表
export function frontSourceList(parameter) {
  return axios({
    url: BASE_URL + `/front/${parameter.source}/list`,
    method: 'get',
    params: parameter
  })
}

// 配置列表 - 自定义虚拟币模板
export function frontSourceUdList(parameter) {
  return axios({
    url: BASE_URL + `/front/${parameter.source}/ud_list`,
    method: 'get',
    params: parameter
  })
}

// 配置排序
export function frontSourceSort(parameter) {
  return axios({
    url: BASE_URL + `/front/${parameter.source}/sort`,
    method: 'post',
    data: parameter
  })
}

// 配置删除
export function frontSourceDelete(parameter) {
  return axios({
    url: BASE_URL + `/front/${parameter.source}/delete`,
    method: 'post',
    data: parameter
  })
}

// 配置详情
export function frontSourceDetail(parameter) {
  return axios({
    url: BASE_URL + `/front/${parameter.source}/detail`,
    method: 'get',
    params: parameter
  })
}

// 项目不分页片库
export function projectDramaAll(parameter) {
  return axios({
    url: BASE_URL + `/project_drama/all`,
    method: 'get',
    params: parameter
  })
}

// banner新建编辑
export function frontBannerEdit(parameter) {
  return axios({
    url: BASE_URL + `/front/banner/edit`,
    method: 'post',
    data: parameter
  })
}

// banner 活动列表
export function frontBannerActivityList(parameter) {
  return axios({
    url: BASE_URL + `/activity/current_list`,
    method: 'get',
    params: parameter
  })
}

// top榜单新建编辑
export function frontTopEdit(parameter) {
  return axios({
    url: BASE_URL + `/front/top/edit`,
    method: 'post',
    data: parameter
  })
}

// 分类模块新建编辑
export function frontModuleEdit(parameter) {
  return axios({
    url: BASE_URL + `/front/module/edit`,
    method: 'post',
    data: parameter
  })
}

// 收费设置 - 通用设置详情
export function frontChargeGeneralSettingDetail(parameter) {
  return axios({
    url: BASE_URL + `/front/pay_detail`,
    method: 'get',
    params: parameter
  })
}

// 收费设置 - 通用设置编辑
export function frontChargeGeneralSettingEdit(parameter) {
  return axios({
    url: BASE_URL + `/front/pay_setting`,
    method: 'post',
    data: parameter
  })
}

// 收费设置 - 列表配置单个详情
export function frontChargeDetail(parameter) {
  return axios({
    url: BASE_URL + `/front/charge/detail/${parameter.id}`,
    method: 'get',
    params: parameter
  })
}

// 收费设置 - 列表配置单个详情 - 自定义虚拟币模板
export function frontChargeUdDetail(parameter) {
  return axios({
    url: BASE_URL + `/front/charge/ud_detail/${parameter.id}`,
    method: 'get',
    params: parameter
  })
}

// 收费设置 - 列表设置单个编辑
export function frontChargeEdit(parameter) {
  return axios({
    url: BASE_URL + `/front/charge/edit`,
    method: 'post',
    data: parameter
  })
}

// 收费设置 - 列表设置单个编辑 - 自定义虚拟币模板
export function frontChargeUdEdit(parameter) {
  return axios({
    url: BASE_URL + `/front/charge/ud_edit`,
    method: 'post',
    data: parameter
  })
}

// 收费设置 - 列表设置单个开关
export function frontChargeSwitch(parameter) {
  return axios({
    url: BASE_URL + `/front/charge/is_open`,
    method: 'post',
    data: parameter
  })
}

// 收费设置 - 广告解锁剧集开关
export function frontChargeSwitchAd(parameter) {
  return axios({
    url: BASE_URL + `/front/charge/is_open_ad`,
    method: 'post',
    data: parameter
  })
}

// 收费设置 - 广告解锁剧集修改
export function frontChargeEditAd(parameter) {
  return axios({
    url: BASE_URL + `/front/charge/edit_ad`,
    method: 'post',
    data: parameter
  })
}

// 任务列表开关
export function frontTaskSwitch(parameter) {
  return axios({
    url: BASE_URL + `/front/task/is_open`,
    method: 'post',
    data: parameter
  })
}


// 站外任务列表开关
export function frontOtherTaskSwitch(parameter) {
  return axios({
    url: BASE_URL + `/front/other_task/is_open`,
    method: 'post',
    data: parameter
  })
}

// 任务详情
export function frontTaskDetail(parameter) {
  return axios({
    url: BASE_URL + `/front/task/detail`,
    method: 'get',
    params: parameter
  })
}


// 站外任务详情
export function frontOtherTaskDetail(parameter) {
  return axios({
    url: BASE_URL + `/front/other_task/detail`,
    method: 'get',
    params: parameter
  })
}


// 任务编辑
export function frontTaskEdit(parameter) {
  return axios({
    url: BASE_URL + `/front/task/edit`,
    method: 'post',
    data: parameter
  })
}



// 站外任务编辑
export function frontOtherTaskEdit(parameter) {
  return axios({
    url: BASE_URL + `/front/other_task/edit`,
    method: 'post',
    data: parameter
  })
}

// 虚拟币配置不分页列表
export function frontChargeAll(parameter) {
  return axios({
    url: BASE_URL + `/front/charge/all`,
    method: 'get',
    params: parameter
  })
}

// 抵扣券管理 - 编辑
export function frontDeductionEdit(parameter) {
  return axios({
    url: BASE_URL + `/front/deduction/save`,
    method: 'post',
    data: parameter
  })
}

// 抵扣券管理 - 不分页列表
export function frontDeductionAll(parameter) {
  return axios({
    url: BASE_URL + `/front/deduction/all`,
    method: 'get',
    params: parameter
  })
}

// 抵扣券管理 - 分页列表
export function frontDeductionList(parameter) {
  return axios({
    url: BASE_URL + `/front/deduction/list`,
    method: 'get',
    params: parameter
  })
}

// 引导配置 - 获取配置详情
export function getLeadConfig(parameter) {
  return axios({
    url: BASE_URL + `/front/lead/detail`,
    method: 'get',
    params: parameter
  })
}

// 引导配置 - 保存配置
export function leadConfigSave(parameter) {
  return axios({
    url: BASE_URL + `/front/lead/save`,
    method: 'post',
    data: parameter
  })
}

// ====================================《 用户管理 》

// 注册用户列表
export function customerList(parameter) {
  return axios({
    url: BASE_URL + `/customer/list`,
    method: 'get',
    params: parameter
  })
}

// 注销用户
export function customerUserCannel(parameter) {
  return axios({
    url: BASE_URL + `/customer/user_cannel`,
    method: 'post',
    data: parameter
  })
}

// 购买记录
export function purchaseList(parameter) {
  return axios({
    url: BASE_URL + `/customer/pay_list`,
    method: 'get',
    params: parameter
  })
}

// 消费记录
export function consumeList(parameter) {
  return axios({
    url: BASE_URL + `/customer/consume_list`,
    method: 'get',
    params: parameter
  })
}

// 消费金额列表
export function consumeMoneyList(parameter) {
  return axios({
    url: BASE_URL + `/customer/money_list`,
    method: 'get',
    params: parameter
  })
}

// 抵扣券记录
export function couponList(parameter) {
  return axios({
    url: BASE_URL + `/customer/coupon_list`,
    method: 'get',
    params: parameter
  })
}

// ===================================《 推 广 管 理 》

// 全部成员列表
export function userAll(parameter) {
  return axios({
    url: BASE_URL + `/user/all`,
    method: 'get',
    params: parameter
  })
}

// 创建推广链接
export function popularizeCreate(parameter) {
  return axios({
    url: BASE_URL + `/popularize/create`,
    method: 'post',
    data: parameter
  })
}

// 推广数据列表
export function popularizeList(parameter) {
  return axios({
    url: BASE_URL + `/popularize/list`,
    method: 'get',
    params: parameter
  })
}

// 推广链接详情数据
export function popularizeDetailData(parameter) {
  return axios({
    url: BASE_URL + `/popularize/detail_data`,
    method: 'get',
    params: parameter
  })
}

// 充值回传操作记录
export function popularizeLogCallback(parameter) {
  return axios({
    url: BASE_URL + `/popularize/log_callback`,
    method: 'get',
    params: parameter
  })
}

// 推广详情数据
export function popularizeData(parameter) {
  return axios({
    url: BASE_URL + `/popularize/popularize_data`,
    method: 'get',
    params: parameter
  })
}

// 推广链接 - 获取刷新时间
export function popularizeRefreshTime(parameter) {
  return axios({
    url: BASE_URL + `/popularize/refresh_time`,
    method: 'get',
    params: parameter
  })
}

// 推广链接 - 刷新接口
export function popularizeRefreshData(parameter) {
  return axios({
    url: BASE_URL + `/popularize/refresh_popularize_data`,
    method: 'get',
    params: parameter
  })
}

// 推广链接 - 自定义列表详情
export function popularizeMenuDetail(parameter) {
  return axios({
    url: BASE_URL + `/popularize/menu_detail`,
    method: 'get',
    params: parameter
  })
}

// 推广链接 - 自定义列表保存
export function popularizeMenuSave(parameter) {
  return axios({
    url: BASE_URL + `/popularize/menu_save`,
    method: 'post',
    data: parameter
  })
}

// 推广链接 - 修改推广链接名称
export function popularizeNameEdit(parameter) {
  return axios({
    url: BASE_URL + `/popularize/update_name`,
    method: 'post',
    data: parameter
  })
}

// 推广链接 - 抵扣券设置 - 列表
export function popularizeDeductionList(parameter) {
  return axios({
    url: BASE_URL + `/popularize/popularize_deduction_list`,
    method: 'get',
    params: parameter
  })
}

// 推广链接 - 抵扣券设置 - 开关
export function popularizeDeductionSwitch(parameter) {
  return axios({
    url: BASE_URL + `/popularize/popularize_deduction_status_update`,
    method: 'post',
    data: parameter
  })
}

// 推广链接 - 抵扣券设置 - 添加编辑
export function popularizeDeductionEdit(parameter) {
  return axios({
    url: BASE_URL + `/popularize/popularize_deduction_save`,
    method: 'post',
    data: parameter
  })
}

// 推广链接 - 抵扣券设置 - 单个启用停用
export function popularizeDeductionStatus(parameter) {
  return axios({
    url: BASE_URL + `/popularize/popularize_deduction_delete`,
    method: 'post',
    data: parameter
  })
}

// 推广链接 - 抵扣券设置 - 上下移
export function popularizeDeductionSort(parameter) {
  return axios({
    url: BASE_URL + `/popularize/popularize_deduction_sort`,
    method: 'post',
    data: parameter
  })
}

// 推广代理商 - 创建代理商
export function popularizeAgentEdit(parameter) {
  return axios({
    url: BASE_URL + `/agent/edit`,
    method: 'post',
    data: parameter
  })
}

// 推广代理商 - 代理商详情
export function popularizeAgentDetail(parameter) {
  return axios({
    url: BASE_URL + `/agent/detail`,
    method: 'get',
    params: parameter
  })
}

// 推广代理商 - 结算单列表
export function getCashList(parameter) {
  return axios({
    url: BASE_URL + `/agent/cash_list`,
    method: 'get',
    params: parameter
  })
}

// 推广代理商 - 未结算结算单列表
export function getNoCashList(parameter) {
  return axios({
    url: BASE_URL + `/agent/no_cash_list`,
    method: 'get',
    params: parameter
  })
}

// 推广代理商 -结算单详情
export function getCashDetail(parameter) {
  return axios({
    url: BASE_URL + `/agent/cash_detail`,
    method: 'get',
    params: parameter
  })
}

// 推广代理商 - 结算单创建
export function cashCreate(parameter) {
  return axios({
    url: BASE_URL + `/agent/cash_create`,
    method: 'post',
    data: parameter
  })
}

// 推广代理商 - 片库设置列表
export function getDramaSettingsList(parameter) {
  return axios({
    url: BASE_URL + `/agent/drama_list`,
    method: 'get',
    params: parameter
  })
}

// 推广代理商 - 是否显示接口
export function dramaSetShow(parameter) {
  return axios({
    url: BASE_URL + `/agent/is_show`,
    method: 'post',
    data: parameter
  })
}

// 推广代理商 - 是否能下载接口
export function dramaSetDownload(parameter) {
  return axios({
    url: BASE_URL + `/agent/is_download`,
    method: 'post',
    data: parameter
  })
}

// 代理商不分页列表
export function popularizeAgentAll(parameter) {
  return axios({
    url: BASE_URL + `/agent/all`,
    method: 'get',
    params: parameter
  })
}

// 代理商分页列表
export function popularizeAgentList(parameter) {
  return axios({
    url: BASE_URL + `/agent/list`,
    method: 'get',
    params: parameter
  })
}

// 状态修改
export function agentStatus(parameter) {
  return axios({
    url: BASE_URL + `/agent/agent_status`,
    method: 'post',
    data: parameter
  })
}

// 推广代理商数据分成
export function popularizeAgentData(parameter) {
  return axios({
    url: BASE_URL + `/agent/data_list`,
    method: 'get',
    params: parameter
  })
}

// ===================================《 推广管理 - 回传数据 》

// 数据回传列表
export function callbackList(parameter) {
  return axios({
    url: BASE_URL + `/callback/list`,
    method: 'get',
    params: parameter
  })
}

// 数据回传列表
export function batchCallback(parameter) {
  return axios({
    url: BASE_URL + `/callback/batch_callback`,
    method: 'post',
    data: parameter
  })
}

// 虚拟数据回传列表
export function callbackVirtualList(parameter) {
  return axios({
    url: BASE_URL + `/callback/virtual_list`,
    method: 'get',
    params: parameter
  })
}

// 虚拟补量详情
export function callbackVirtualDetail(parameter) {
  return axios({
    url: BASE_URL + `/callback/virtual_detail`,
    method: 'post',
    data: parameter
  })
}

// 创建虚拟补量
export function virtualCallback(parameter) {
  return axios({
    url: BASE_URL + `/callback/virtual_callback`,
    method: 'post',
    data: parameter
  })
}

// ===================================《 企业微信 》

// 客服关键字回复列表
export function messageSustomerList(parameter) {
  return axios({
    url: BASE_URL + `/message/customer_list`,
    method: 'get',
    params: parameter
  })
}

// 客服消息关键字回复添加
export function messageSustomerSave(parameter) {
  return axios({
    url: BASE_URL + `/message/customer_save`,
    method: 'post',
    data: parameter
  })
}

// 修改客服关键字回复状态
export function messageSustomerStatus(parameter) {
  return axios({
    url: BASE_URL + `/message/customer_status`,
    method: 'post',
    data: parameter
  })
}

// 好友推送列表
export function messageFriendList(parameter) {
  return axios({
    url: BASE_URL + `/message/friend_list`,
    method: 'get',
    params: parameter
  })
}

// 好友推送添加或修改
export function messageFriendSave(parameter) {
  return axios({
    url: BASE_URL + `/message/friend_save`,
    method: 'post',
    data: parameter
  })
}

// 取消或重新推送
export function messageSendMessage(parameter) {
  return axios({
    url: BASE_URL + `/message/send_message`,
    method: 'post',
    data: parameter
  })
}

// 客服列表
export function messageFollowList(parameter) {
  return axios({
    url: BASE_URL + `/message/follow_list`,
    method: 'get',
    params: parameter
  })
}

// 企业客服列表
export function messageKfList(parameter) {
  return axios({
    url: BASE_URL + `/message/kf_list`,
    method: 'get',
    params: parameter
  })
}

// 添加企业客服
export function messageKfSave(parameter) {
  return axios({
    url: BASE_URL + `/message/kf_save`,
    method: 'post',
    data: parameter
  })
}

// 微小推送统计列表
export function pushList(parameter) {
  return axios({
    url: BASE_URL + `/push/push_list`,
    method: 'get',
    params: parameter
  })
}

// 抖小推送统计列表
export function pushListTt(parameter) {
  return axios({
    url: BASE_URL + `/push/dy_push_list`,
    method: 'get',
    params: parameter
  })
}

// 推送设置列表
export function pushSettinsList(parameter) {
  return axios({
    url: BASE_URL + `/push/list`,
    method: 'get',
    params: parameter
  })
}

// 推送调序
export function pushSort(parameter) {
  return axios({
    url: BASE_URL + `/push/sort`,
    method: 'post',
    data: parameter
  })
}

// 推送信息修改
export function pushUpdateContent(parameter) {
  return axios({
    url: BASE_URL + `/push/update_content`,
    method: 'post',
    data: parameter
  })
}

// 是否推送修改
export function pushIsPush(parameter) {
  return axios({
    url: BASE_URL + `/push/is_push`,
    method: 'post',
    data: parameter
  })
}

// 微小订阅推送列表 - 导出充值明细
export function exportPush(parameter) {
  return axios({
    url: BASE_URL + `/push/export_push`,
    method: 'post',
    data: parameter
  })
}

// 抖小订阅推送列表 - 导出充值明细
export function exportPushTt(parameter) {
  return axios({
    url: BASE_URL + `/push/dy_export_push`,
    method: 'post',
    data: parameter
  })
}

// 好友推送列表 - 导出充值明细
export function exportMessage(parameter) {
  return axios({
    url: BASE_URL + `/message/export_message`,
    method: 'post',
    data: parameter
  })
}

// ===================================《 结算中心 》

// 每日分成数据
export function cashDayList(parameter) {
  return axios({
    url: BASE_URL + `/cash/cash_day_list`,
    method: 'get',
    params: parameter
  })
}

// 项目结算总信息
export function cashProjectCash(parameter) {
  return axios({
    url: BASE_URL + `/cash/project_cash`,
    method: 'get',
    params: parameter
  })
}

// 开始结算
export function cashBeginCash(parameter) {
  return axios({
    url: BASE_URL + `/cash/begin_cash`,
    method: 'get',
    params: parameter
  })
}

// 结算
export function cashCashSave(parameter) {
  return axios({
    url: BASE_URL + `/cash/cash_save`,
    method: 'post',
    data: parameter
  })
}

// 结算列表
export function cashList(parameter) {
  return axios({
    url: BASE_URL + `/cash/cash_list`,
    method: 'get',
    params: parameter
  })
}

// 企微列表 - 选中展示列表
export function wechatAccount(parameter) {
  return axios({
    url: BASE_URL + '/wechat/wechat_account',
    method: 'get',
    params: parameter
  })
}


export function typeIdList(parameter) {
  return axios({
    url: BASE_URL + '/project_drama/type_list',
    method: 'get',
    params: parameter
  })
}


export function navSave(parameter) {
  return axios({
    url: BASE_URL + `/project_drama/nav_save`,
    method: 'post',
    data: parameter
  })
}


export function iconNavSave(parameter) {
  return axios({
    url: BASE_URL + `/project_drama/icon_nav_save`,
    method: 'post',
    data: parameter
  })
}
iconNavSave